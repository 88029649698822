<template>
  <div>
    <section class="section has-background-white">
      <div class="lead has-text-centered">
        <h1 class="is-size-1 has-text-grey-dark has-text-extra-bold has-font-alternate">Launch your product today.</h1>
        <h4 class="is-size-4 has-text-weight-light has-text-grey has-small-margin-top">
          Floom handles user authentication, billing, subscriptions, and bookkeeping for you.
          <br />So you can focus on building your product.
        </h4>
      </div>
    </section>
    <section class="section">
      <div class="container video-player">
        <video
          preload="true"
          playsinline="true"
          autoplay="true"
          loop="true"
          muted="muted"
          src="https://s3.amazonaws.com/floom-resources/Introducing+Floom.mp4"
        ></video>
        <div class="video-player__controls" v-if="video">
          <el-button round icon="el-icon-bell" @click="muteUnmute">
            <span class="is-hidden-touch">Play with sound</span>
            <span class="is-hidden-desktop"></span>
          </el-button>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container narrowish-container">
        <el-image style="width:100%;" :src="require('@/assets/venn-diagram.png')" fit="contain"></el-image>
      </div>
    </section>
    <div class="has-background-white-light">
      <section class="section">
        <div class="container narrower-container">
          <h2 class="is-size-2 has-text-centered has-font-alternate">How It Works</h2>
          <div class="divider has-margin-bottom"></div>
          <br />
          <div :style="{ padding: '40px', borderRadius: '16px' }" class="has-diffused-shadow has-background-white">
            <div class="columns is-vcentered">
              <div class="column is-7">
                <div class="has-padding-horizontal has-padding-vertical">
                  <h3 class="is-size-5 has-text-weight-semibold has-font-alternate">
                    Built By Developers for Developers
                  </h3>
                  <div class="has-margin-top is-size-6-7">
                    <p>
                      <b>
                        Floom operates on a
                        <i>BYO-API</i> (bring your own API) philosophy:
                      </b>
                      you bring your value-providing API and let Floom take care of the rest.
                    </p>
                    <div class="has-margin-top">
                      For instance, let's say you're a machine learning specialist and you built an API which removes
                      the background from images. Tell Floom where your API endpoint lives and what input it needs (e.g.
                      you need a
                      <code class="has-text-primary">POST</code> request sent to
                      <code class="has-text-primary">https://api.example.com/background/create</code> with a body that
                      looks like this <code class="has-text-primary">{ img: [image url] }</code>. For complete API
                      details,
                      <a class="has-text-weight-bold" href="https://docs.floom.app/sellers" target="_blank"
                        >check out our docs</a
                      >.
                    </div>
                    <div class="has-margin-top">
                      Set your price (either pay-per-transaction or subscription plans). We'll automatically generate
                      your product page complete with form inputs for everything your API needs (e.g. a file uploader
                      for your API which requires a user image).
                    </div>
                    <div class="has-margin-top">
                      Whenever a customer places an order, we'll send a request to your API. Your API fulfills the order
                      and you get paid.
                    </div>
                    <div class="has-margin-top">
                      <b>It really is that simple.</b>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-5">
                <ul class="is-checked-list is-size-6-7 has-text-weight-semibold">
                  <li class="checked-item">API-driven Architecture</li>
                  <li class="checked-item">Automated Subscription Billing</li>
                  <li class="checked-item">Seller Analytics</li>
                  <li class="checked-item">Service Features and Reviews</li>
                  <li class="checked-item">Webhooks & Polling Options for Long-Running Services</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- <section class="section">
      <div class="container narrower-container">
        <h2 class="is-size-2 has-text-centered has-font-alternate">Meet the Team</h2>
        <div class="divider has-margin-bottom"></div>
        <br>
        <el-card style="max-width:300px;">
          <el-image :src="require('@/assets/Rob.jpg')" fit="contain" style="width:100%;border-radius:100%;">
          </el-image>
        </el-card>
      </div>
      </section>-->
      <section class="section has-large-margin-top has-large-margin-bottom has-background-white">
        <div class="container has-text-centered has-large-padding-top has-large-padding-bottom">
          <h2 class="is-size-2 has-font-alternate">Building something?</h2>
          <p class="has-text-grey-darker has-small-margin-top is-size-5">Launch on Floom.</p>
          <br />
          <!-- <a href="https://docs.floom.app/sellers" target="_blank">
          <el-button type="default" round>Learn More</el-button>
          </a>-->
          <a href="mailto:rob@floom.app">
            <el-button type="default" round>Tell Us About Your Product</el-button>
          </a>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  data() {
    return {
      video: null,
    }
  },
  mounted() {
    this.video = document.querySelector('video')
    document.dispatchEvent(new Event('x-app-rendered'))
  },
  computed: {
    videoPaused() {
      return this.video && this.video.paused
    },
  },
  methods: {
    muteUnmute() {
      this.video.muted = !this.video.muted
    },
    playPause() {
      if (this.video.paused) {
        this.video.play()
      } else {
        this.video.pause()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.video-player {
  position: relative;
  .video-player__controls {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  video {
    border-radius: 20px;
  }
}

.has-background-white-light {
  background-image: linear-gradient(180deg, #fff, #fff 30%, #e3ddf86e 30%);
}

.lead {
  margin-top: 20px;
}
</style>
